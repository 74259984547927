













import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import {
  ButtonVariant,
  ButtonVariantType,
  Variant,
  VariantType
} from '~/models/app/variant'
import { Size } from '~/models/app/size'

export enum CSpinnerType {
  GROW = 'grow',
  BORDER = 'border'
}

export default defineComponent({
  props: {
    type: {
      type: String as PropType<CSpinnerType>,
      default: CSpinnerType.BORDER
    },
    size: {
      type: String as PropType<Size>,
      default: 'md'
    },
    sizeClass: {
      type: String,
      required: false
    },
    borderClass: {
      type: String,
      required: false
    },
    variant: {
      type: String as PropType<VariantType | ButtonVariantType>,
      default: Variant.PRIMARY
    }
  },
  setup(props) {
    const { variant, type, size, sizeClass, borderClass } = toRefs(props)

    const variantColor = computed(() => {
      switch (variant.value) {
        case Variant.PRIMARY:
        default: {
          return 'tw-text-primary'
        }
        case Variant.SECONDARY: {
          return 'tw-text-blue-500'
        }
        case Variant.SUCCESS: {
          return 'tw-text-green'
        }
        case Variant.DANGER: {
          return 'tw-text-red-400'
        }
        case Variant.WARNING: {
          return 'tw-text-amber'
        }
        case ButtonVariant.NEUTRAL: {
          return 'tw-text-grey-600'
        }
        case ButtonVariant.OFFER: {
          return 'tw-text-offer-green-500'
        }
        case ButtonVariant.PARKING: {
          return 'tw-text-blue-300'
        }
        case ButtonVariant.VIBER: {
          return 'tw-text-viber'
        }
        case ButtonVariant.WHATSAPP: {
          return 'tw-text-whatsapp'
        }
        case ButtonVariant.TURBO: {
          return 'tw-text-turbo'
        }
        case ButtonVariant.SUPER: {
          return 'tw-text-super'
        }
        case ButtonVariant.TOP: {
          return 'tw-text-super'
        }
        case ButtonVariant.FACEBOOK: {
          return 'tw-text-facebook'
        }
        case ButtonVariant.X: {
          return 'tw-text-x'
        }
        case ButtonVariant.TBI: {
          return 'tw-text-tbi'
        }
        case Variant.WHITE: {
          return 'tw-text-white'
        }
        case Variant.LIGHT: {
          return 'tw-text-grey'
        }
      }
    })
    const internalSizeClass = computed(() => {
      if (sizeClass.value) {
        return sizeClass.value
      }
      switch (size.value) {
        case 'xs': {
          return 'tw-w-4 tw-h-4'
        }
        case 'sm': {
          return 'tw-w-6 tw-h-6'
        }
        case 'md': {
          return 'tw-w-8 tw-h-8'
        }
        case 'lg': {
          return 'tw-w-12 tw-h-12'
        }
        case 'xl': {
          return 'tw-w-14 tw-h-14'
        }
        case 'xxl': {
          return 'tw-w-20 tw-h-20'
        }
        default: {
          return 'tw-w-8 tw-h-8'
        }
      }
    })
    const bgColor = computed(() => {
      if (type.value === CSpinnerType.BORDER) {
        return 'tw-bg-transparent'
      }
      switch (variant.value) {
        case Variant.SECONDARY: {
          return 'tw-bg-blue-500'
        }
        case Variant.SUCCESS: {
          return 'tw-bg-green'
        }
        case Variant.WARNING: {
          return 'tw-bg-amber'
        }
        case Variant.DANGER: {
          return 'tw-bg-red-400'
        }
        case Variant.WHITE: {
          return 'tw-bg-white'
        }
        case Variant.LIGHT: {
          return 'tw-bg-grey'
        }
        case Variant.PRIMARY:
        default: {
          return 'tw-bg-primary'
        }
      }
    })

    const typeClass = computed(() => {
      if (type.value === CSpinnerType.GROW) {
        return 'grow-spinner'
      }
      return 'border-spinner'
    })

    const internalBorderClass = computed(() => {
      if (borderClass.value) {
        return borderClass.value
      }
      if (type.value === CSpinnerType.BORDER) {
        return ['tw-border-4']
      }
      return []
    })

    return {
      variantColor,
      typeClass,
      bgColor,
      internalSizeClass,
      internalBorderClass
    }
  }
})
