import { SearchConfig } from '~/models/search/config'
import { CategoryId } from '~/models/category/types'

export const wantedPartsSearchConfig: SearchConfig = Object.freeze({
  settings: {
    searchName: 'wanted-parts',
    showAdminMassActions: true,
    galleryLgPerRow: 2,

    searchbar: {
      autocompleteEndpoint: '/api/classifieds/my/query/',
      usesNuxtLinks: false
    },
    displayAds: true
  },
  rootParams: {
    category: [CategoryId.WANTED_PARTS]
  }
})
