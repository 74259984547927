
import {
  computed,
  defineComponent,
  h,
  PropType,
  ref,
  toRefs
} from '@nuxtjs/composition-api'
import { Size } from '~/models/shared/types'
import { useVNode } from '~/compositions/vnode'
import { VNode } from 'vue'
import CInputGroupText from '~/components/shared/configurable/form/input/CInputGroupText.vue'
import { useFormComponent } from '~/compositions/form-component'
import CIcon from '~/components/shared/configurable/CIcon.vue'
import { useId } from '~/compositions/id'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<Size>,
      default: Size.MEDIUM,
      required: false
    },
    prepend: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    inputClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    inputRoundnessClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    hasError: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    helperText: {
      type: String,
      default: null
    },
    insetButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      default: null
    }
  },
  setup(props, { slots }) {
    const {
      size,
      prepend,
      append,
      hasError,
      success,
      warning,
      disabled,
      helperText,
      insetButtons,
      inputClass,
      inputRoundnessClass,
      id
    } = toRefs(props)

    const { addPropertiesToVNodes, addPropertiesToVNode } = useVNode()

    const focused = ref(false)

    const { uuid } = useId()

    const defaultSlotComponent = computed(() => {
      const defaultSlots = slots.default()
      if (defaultSlots?.length) {
        const mainComponent = defaultSlots.find(s => s.componentOptions)
        return mainComponent
      }

      return undefined
    })
    const idToUse = computed(() => {
      if (defaultSlotComponent.value?.componentOptions?.propsData?.id) {
        return defaultSlotComponent.value.componentOptions.propsData.id
      }

      if (id.value) {
        return id.value
      }

      return uuid()
    })

    const disabledToUse = computed(() => {
      return disabled.value
    })

    const { helperTextClasses, helperTextIcon } = useFormComponent(
      ref(false),
      ref(false),
      ref(''),
      hasError,
      success,
      warning,
      'input-group'
    )

    const hasPrependedContent = computed(() => {
      if (slots.prepend) {
        const nodes = slots.prepend()
        return Boolean(nodes?.length)
      }

      return Boolean(prepend.value)
    })
    const hasAppendedContent = computed(() => {
      if (slots.append) {
        const nodes = slots.append()
        return Boolean(nodes?.length)
      }

      return Boolean(append.value)
    })

    const inputClasses = computed(() => {
      const classes = []
      if (
        !inputRoundnessClass.value ||
        inputRoundnessClass.value?.length === 0
      ) {
        if (hasPrependedContent.value && hasAppendedContent.value) {
          classes.push('!tw-rounded-none')
        }
        if (hasPrependedContent.value) {
          classes.push('!tw-rounded-l-none')
        }
        if (hasAppendedContent.value) {
          classes.push('!tw-rounded-r-none')
        }
      }

      if (size.value === Size.LARGE) {
        classes.push('!tw-px-3')
      } else {
        classes.push('!tw-px-2')
      }

      return [
        inputClass.value,
        inputRoundnessClass.value,
        ...classes,
        '!tw-outline-none focus:!tw-ring-transparent !tw-border-transparent'
      ]
    })

    function vNodeIsButton(vNode: VNode): boolean {
      return vNode.componentOptions?.tag === 'CButton'
    }
    function vNodeIsInputGroupText(vNode: VNode): boolean {
      return vNode.tag?.endsWith('CInputGroupText')
    }
    function vNodeIsSelect(vNode: VNode): boolean {
      return ['CFormSelect', 'CCustomSelect'].includes(
        vNode.componentOptions?.tag
      )
    }

    function renderContentBeforeAndAfter(
      vNodes: VNode[],
      placement: 'append' | 'prepend'
    ): VNode {
      if (!vNodes || vNodes.length === 0) {
        return
      }
      const [node] = vNodes
      const isPrepended = placement === 'prepend'
      let roundnessClass
      if (isPrepended) {
        switch (size.value) {
          case Size.SMALL: {
            roundnessClass = 'tw-rounded-l-md'
            break
          }
          case Size.LARGE: {
            roundnessClass = 'tw-rounded-l-xl'
            break
          }
          case Size.MEDIUM:
          default: {
            roundnessClass = 'tw-rounded-l-lg'
            break
          }
        }
      } else {
        switch (size.value) {
          case Size.SMALL: {
            roundnessClass = 'tw-rounded-r-md'
            break
          }
          case Size.LARGE: {
            roundnessClass = 'tw-rounded-r-xl'
            break
          }
          case Size.MEDIUM:
          default: {
            roundnessClass = 'tw-rounded-r-lg'
            break
          }
        }
      }

      if (vNodeIsButton(node)) {
        let staticClasses

        if (!insetButtons.value) {
          staticClasses = isPrepended
            ? 'tw-rounded-r-none'
            : 'tw-rounded-l-none'
        } else {
          staticClasses = size.value === Size.SMALL ? 'tw-mr-1' : 'tw-mr-2'
        }

        let sizeToUse = size.value

        if (insetButtons.value) {
          switch (size.value) {
            case Size.LARGE: {
              sizeToUse = Size.SMALL
              break
            }
            case Size.SMALL:
            case Size.MEDIUM:
            default: {
              sizeToUse = Size.EXTRA_SMALL
              break
            }
          }
        }
        return h(
          'div',
          { class: { 'tw-self-center': insetButtons.value } },
          addPropertiesToVNodes(vNodes, {
            props: { size: sizeToUse },
            staticClasses,
            disabled: disabledToUse.value
          })
        )
      } else if (vNodeIsSelect(node)) {
        let borderClasses

        if (isPrepended) {
          borderClasses =
            'tw-border tw-border-r-solid !tw-rounded-r-none !tw-border-grey-300'
        } else {
          borderClasses =
            'tw-border tw-border-l-solid !tw-rounded-l-none !tw-border-grey-300'
        }

        const propsToUse = {
          size: size.value,
          roundnessClass,
          hasError: hasError.value,
          success: success.value,
          warning: warning.value,
          disabled: disabledToUse.value
        }

        if (node.componentOptions?.tag === 'CCustomSelect') {
          propsToUse.headerClasses = `!tw-outline-none !tw-ring-transparent focus:!tw-ring-transparent tw-select-none ${borderClasses}`
        } else {
          propsToUse.selectClass = `!tw-outline-none focus:!tw-ring-transparent tw-select-none ${borderClasses}`
        }
        return h(
          'div',
          { staticClass: 'tw-inline-flex' },
          addPropertiesToVNodes(vNodes, {
            props: propsToUse
          })
        )
      } else if (vNodeIsInputGroupText(node)) {
        return h(
          'div',
          { staticClass: 'tw-inline-flex' },
          addPropertiesToVNodes(vNodes, {
            props: {
              size: size.value,
              hasError: hasError.value,
              success: success.value,
              warning: warning.value,
              disabled: disabledToUse.value,
              isPrepended,
              forId: idToUse.value
            }
          })
        )
      }
      return h(
        'div',
        { staticClass: 'tw-inline-flex' },
        addPropertiesToVNodes(vNodes, {
          props: {
            size: size.value,
            roundnessClass,
            isPrepended,
            hasError: hasError.value,
            success: success.value,
            warning: warning.value,
            disabled: disabledToUse.value
          },
          staticClasses: isPrepended ? '-tw-mr-px' : '-tw-ml-px'
        })
      )
    }

    const defaultSlotIsSelect = computed(() => {
      return defaultSlotComponent.value
        ? vNodeIsSelect(defaultSlotComponent.value)
        : false
    })

    function renderContent(): VNode[] {
      const r = []
      if (slots.prepend) {
        r.push(renderContentBeforeAndAfter(slots.prepend(), 'prepend'))
      } else if (prepend.value) {
        r.push(
          renderContentBeforeAndAfter(
            [h(CInputGroupText, prepend.value)],
            'prepend'
          )
        )
      }
      if (slots.default) {
        const propsToUse = {
          size: size.value,
          hasError: hasError.value,
          success: success.value,
          warning: warning.value,
          disabled: disabledToUse.value,
          id: idToUse.value
        }

        if (defaultSlotIsSelect.value) {
          propsToUse.selectClass = inputClasses.value
        } else {
          propsToUse.inputClass = inputClasses.value
        }
        r.push(
          h(
            'div',
            {
              staticClass: 'tw-flex-1'
            },
            addPropertiesToVNodes(slots.default(), {
              props: propsToUse,
              listeners: {
                focus: () => {
                  focused.value = true
                },
                blur: () => {
                  focused.value = false
                }
              }
            })
          )
        )
      }
      if (slots.append) {
        r.push(renderContentBeforeAndAfter(slots.append(), 'append'))
      } else if (append.value) {
        r.push(
          renderContentBeforeAndAfter(
            [h(CInputGroupText, append.value)],
            'append'
          )
        )
      }
      return r
    }

    const wrapperRoundnessClass = computed(() => {
      switch (size.value) {
        case Size.SMALL: {
          return 'tw-rounded-md'
        }
        case Size.LARGE: {
          return 'tw-rounded-xl'
        }
        case Size.MEDIUM:
        default: {
          return 'tw-rounded-lg'
        }
      }
    })
    const wrapperStateClasses = computed(() => {
      if (hasError.value) {
        return 'aria-selected:tw-ring-red-400 aria-selected:tw-border-red-400 hover:tw-border-red-400 tw-border-red-400'
      } else if (success.value) {
        return 'aria-selected:tw-ring-green-500 aria-selected:tw-border-green-500 hover:tw-border-green-500 tw-border-green-500'
      } else if (warning.value) {
        return 'aria-selected:tw-ring-amber-500 aria-selected:tw-border-amber-500 hover:tw-border-amber-500 tw-border-amber-500'
      } else if (disabledToUse.value) {
        return 'tw-bg-grey-300 tw-text-grey-600 tw-border-grey-300 tw-cursor-not-allowed'
      }
      return 'tw-bg-white hover:tw-border-blue-500 aria-selected:tw-border-blue-500 aria-selected:tw-ring-blue-500 tw-border-grey-300'
    })
    return () => {
      const children = [
        h(
          'div',
          {
            attrs: {
              role: 'group',
              'aria-selected': focused.value.toString()
            },
            staticClass:
              'tw-flex tw-relative tw-flex-wrap tw-items-stretch tw-w-full tw-border tw-border-solid',
            class: [
              wrapperRoundnessClass.value,
              wrapperStateClasses.value,
              'tw-transition-colors aria-selected:tw-ring-1'
            ]
          },
          renderContent()
        )
      ]

      if (helperText.value || slots.helperText) {
        if (slots.helperText) {
          children.push(
            h(
              'div',
              { class: helperTextClasses.value },
              addPropertiesToVNode(slots.helperText())
            )
          )
        } else if (helperText.value) {
          const helperTextChildren = []
          if (helperTextIcon.value) {
            helperTextChildren.push(
              h(CIcon, {
                props: { icon: helperTextIcon.value, size: 16 }
              })
            )
          }
          helperTextChildren.push(h('span', helperText.value))

          children.push(
            h('div', { class: helperTextClasses.value }, [
              h(
                'span',
                { staticClass: 'tw-inline-flex tw-items-center tw-gap-1' },
                helperTextChildren
              )
            ])
          )
        }
      }

      return h(
        'div',
        {
          staticClass:
            'tw-flex tw-relative tw-flex-col tw-items-stretch tw-w-full'
        },
        children
      )
    }
  }
})
