import Vue from 'vue'
import { MutationTree } from 'vuex'
import defaultViewState, {
  ClassifiedViewState
} from '~/store/modules/shared/classifieds/view/state'
import {
  RESET_STATE,
  SET_BIG_PHOTOS_VISIBLE,
  SET_YOUTUBE_VISIBLE,
  SET_CLASSIFIED,
  SET_CLASSIFIED_ID,
  SET_CONTACT_SCHEMA,
  SET_DATA,
  SET_DEBUG_SHIMMER,
  SET_EXTERNAL_CERTIFICATE,
  SET_HIDE_PROMOTION,
  SET_REFS,
  SET_SHOW_CONTACT,
  SET_SHOW_CONTACT_PROMPT,
  SET_CURRENT_USER_IS_PARKED,
  SET_LEASING_WITH_VAT,
  SET_LEASING_AVAILABLE_DURATIONS,
  SET_LEASING_OPTION,
  SET_LEASING_DURATION,
  SET_INSTALLMENTS_DOWNPAYMENT,
  SET_INSTALLMENTS_INSTALLMENT_AMOUNT,
  SET_INSTALLMENTS_NUMBER_OF_INSTALLMENTS,
  SET_PROCESSED_PHOTOS,
  REMOVE_PROCESSED_PHOTO,
  SET_AUTO_TOUCH_DATA_IN_VIEW,
  SET_HAS_UNREAD_MESSAGES,
  SET_FLOATING_BUTTONS_MESSAGE_PHONE_VISIBILITY,
  SET_CLASSIFIED_NOTES,
  SET_VARIANT,
  SET_OFFER_COUNT,
  SET_OFFER_THREAD_READ,
  SET_CLASSIFIED_OFFERS,
  SET_HAS_OFFERS_FOR_CLASSIFIED,
  REMOVE_CLASSIFIED_OFFERS,
  UPDATE_CLASSIFIED_OFFER,
  SET_AUDIT_REQUESTS,
  SET_CONTACT_FORM_DATA,
  UPDATE_AUDIT_REQUEST,
  SET_AUDITS,
  SET_MESSAGE_STATS
} from '~/store/modules/shared/classifieds/view/mutation-types'
import { createMutationCreator } from '~/factories/store/mutation'
import { LeasingOption } from '~/models/classified/leasing/types'
import { toSnakeCase } from '~/utils/snake-case'
import { AutoTouchSchedule } from '~/models/auto-touch/types'
import { ContactFormData, ViewResult } from '~/models/classified/view'
import { ClassifiedNote } from '~/models/classified/note'
import { ClassifiedOfferEntry } from '~/models/classified/offers'
import { toCamelCase } from '~/utils/object'
import { AuditRequest } from '~/models/audit/request'

const { setter } = createMutationCreator<ClassifiedViewState>()

export default {
  ...setter(SET_DEBUG_SHIMMER, 'debugShimmer'),
  ...setter(SET_CLASSIFIED_ID, 'classifiedId'),
  ...setter(SET_CLASSIFIED, 'classified'),
  ...setter(SET_BIG_PHOTOS_VISIBLE, 'bigPhotosVisible'),
  ...setter(SET_YOUTUBE_VISIBLE, 'youtubeVisible'),
  ...setter(SET_SHOW_CONTACT, 'showContact'),
  ...setter(SET_CONTACT_SCHEMA, 'contactSchema'),
  ...setter(SET_MESSAGE_STATS, 'messageStats'),
  ...setter(SET_SHOW_CONTACT_PROMPT, 'showContactPrompt'),
  ...setter(SET_HIDE_PROMOTION, 'hidePromotion'),
  ...setter(SET_PROCESSED_PHOTOS, 'processedPhotos'),
  ...setter(SET_HAS_UNREAD_MESSAGES, 'hasUnreadMessages'),
  ...setter(SET_VARIANT, 'variant'),
  ...setter(SET_OFFER_COUNT, 'offerCount'),
  ...setter(SET_CLASSIFIED_OFFERS, 'classifiedOffers'),
  ...setter(SET_HAS_OFFERS_FOR_CLASSIFIED, 'hasOfferForClassified'),
  ...setter(SET_AUDIT_REQUESTS, 'auditRequests'),
  ...setter(
    SET_FLOATING_BUTTONS_MESSAGE_PHONE_VISIBILITY,
    'isFloatingButtonsMessagePhoneVisible'
  ),

  [SET_DATA](state, data: ViewResult) {
    const {
      seller,
      page,
      consent,
      adsTargetings,
      classified,
      currentUser,
      extra,
      promotion,
      paidClassified,
      ads,
      rental,
      audits,
      seoUrl,
      insurance,
      marketplace
    } = data
    state.classified = classified
    state.page = page
    state.currentUser = currentUser
    state.consent = consent
    state.seller = seller
    state.adsTargetings = adsTargetings
    state.ads = ads
    state.extra = extra
    state.promotion = promotion
    state.paidClassified = paidClassified
    state.rental = rental
    state.audits = audits
    state.seoUrl = seoUrl
    state.insurance = insurance
    state.marketplace = marketplace
  },
  [RESET_STATE](state) {
    const dState = () => {
      return {
        ...defaultViewState(),
        ...{
          refs: state.refs
        }
      }
    }
    Object.assign(state, dState())
  },
  [SET_CURRENT_USER_IS_PARKED](state, isParked) {
    if (state.currentUser) {
      Vue.set(state.currentUser, 'is_parked', isParked)
    }
  },
  [SET_REFS](state, refs) {
    if (!refs) {
      state.refs = null
      return
    }
    state.refs = refs
  },
  [SET_LEASING_OPTION](state, leasingOption: LeasingOption) {
    state.leasing.option = leasingOption
  },
  [SET_LEASING_WITH_VAT](state, withVat: boolean) {
    state.leasing.withVat = withVat
  },
  [SET_LEASING_AVAILABLE_DURATIONS](state, availableDurations: string[]) {
    state.leasing.availableDurations = availableDurations
  },
  [SET_LEASING_DURATION](state, duration: string) {
    state.leasing.duration = duration
  },
  [SET_INSTALLMENTS_DOWNPAYMENT](state, downpayment: number) {
    state.installments.downpayment = downpayment
  },
  [SET_INSTALLMENTS_INSTALLMENT_AMOUNT](state, installmentAmount: number) {
    state.installments.installmentAmount = installmentAmount
  },
  [SET_INSTALLMENTS_NUMBER_OF_INSTALLMENTS](
    state,
    numberOfInstallments: number
  ) {
    state.installments.numberOfInstallments = numberOfInstallments
  },
  [SET_HIDE_PROMOTION](state, hideState) {
    state.hidePromotion = hideState
  },
  [SET_EXTERNAL_CERTIFICATE](state, externalCertificates) {
    if (state.classified) {
      state.classified.external_certificates = {
        ...state.classified.external_certificates,
        ...externalCertificates,
        general_info: {
          ...externalCertificates.certificate.details.general_info
        }
      }
    }
  },
  [REMOVE_PROCESSED_PHOTO](state, index) {
    if (state.processedPhotos.length) {
      Vue.delete(state.processedPhotos, index)
    }
  },
  [SET_AUTO_TOUCH_DATA_IN_VIEW](
    state,
    autoTouchData: AutoTouchSchedule | null
  ) {
    if (state.classified) {
      if (autoTouchData) {
        Vue.set(state.classified, 'auto_touch', {
          ...state.classified.auto_touch,
          ...toSnakeCase(autoTouchData)
        })
      } else {
        state.classified.auto_touch = null
      }
    }
  },
  [SET_CLASSIFIED_NOTES](state, note: ClassifiedNote | null) {
    if (note?.notes) {
      state.notes = note
    } else {
      state.notes = null
    }
  },
  [SET_OFFER_THREAD_READ](
    state,
    { threadId, read }: { threadId: string; read: boolean }
  ) {
    const rowIndex = state.classifiedOffers.findIndex(c => {
      return c.thread?.id.toString() === threadId
    })

    if (rowIndex >= 0) {
      const editedRow = state.classifiedOffers[rowIndex]
      editedRow.thread.threadRead = read

      Vue.set(state.classifiedOffers, rowIndex, editedRow)
    }
  },
  [REMOVE_CLASSIFIED_OFFERS](state, offerID: number) {
    state.classifiedOffers = [
      ...state.classifiedOffers.filter(o => o.id !== offerID)
    ]
  },
  [UPDATE_CLASSIFIED_OFFER](state, updatedOffer: ClassifiedOfferEntry) {
    Vue.set(
      state.classifiedOffers,
      state.classifiedOffers.findIndex(o => o.id === updatedOffer.id),
      updatedOffer
    )
  },
  [SET_CONTACT_FORM_DATA](state, contactFormData: ContactFormData) {
    state.contactFormData = toCamelCase(contactFormData)
  },
  [UPDATE_AUDIT_REQUEST](state, audit: AuditRequest) {
    if (state.auditRequests?.length) {
      const index = state.auditRequests.findIndex(
        (r: AuditRequest) => r.id === audit.id
      )
      Vue.set(state.auditRequests, index, audit)
    }
  },
  [SET_AUDITS](state, audits) {
    state.audits = audits
  }
} as MutationTree<ClassifiedViewState>
