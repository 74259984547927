import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { FieldValues } from '~/models/form/types'
import { CategoryId } from '~/models/category/types'

@containerScoped()
export default class IncompleteClassifiedService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  submitIncompleteClassified({
    form,
    categoryId
  }: {
    form: FieldValues
    categoryId: CategoryId
  }): Promise<{ incompleteClassified: FieldValues }> {
    return this.requestBuilder
      .request('post', `/api/incomplete-classifieds/${categoryId}/submit/`)
      .data({
        form
      })
      .send()
  }
}
