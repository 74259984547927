import { AxiosInstance, AxiosResponse } from 'axios'
import qs from 'qs'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { SearchesBucketType } from '~/models/search-bucket/types'
import { ActionResult } from '~/models/shared/result'
import { Search, SearchOfBucket } from '~/models/search/types'
import { invalidBodyError } from '../errors'
import { CategoryId } from '~/models/category/types'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class SearchBucketService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  getBucketSearches(bucket: SearchesBucketType): Promise<SearchOfBucket[]> {
    return this.requestBuilder
      .request('get', `/api/searches/buckets/${bucket}/`)
      .validate(body => body && body.data && body.data.searches)
      .map(body => {
        return body.data.searches
      })
      .send()
  }

  addSearchToFavorites(
    searchId: string,
    oldSearchId?: string
  ): Promise<any | null> {
    return this.requestBuilder
      .request('put', `/api/searches/buckets/${SearchesBucketType.FAVORITE}/`)
      .data({
        id: searchId,
        old_search: oldSearchId
      })
      .validate(body => body && body.status !== 404)
      .action()
      .send()
  }

  editFavoriteSearch(searchId: string, data: { [key: string]: unknown }) {
    return this.requestBuilder
      .request('put', `/api/searches/buckets/${SearchesBucketType.FAVORITE}/`)
      .data({ id: searchId, ...data })
      .validate(body => body && body.status !== 404)
      .action()
      .send()
  }

  addToSeenBucket(classifiedId: number) {
    return this.requestBuilder
      .request('put', `/api/classifieds/buckets/${SearchesBucketType.SEEN}/`)
      .data({
        classified: classifiedId
      })
      .validate(body => body)
      .action()
      .send()
  }

  removeSeenFromBucket(id: number | string): Promise<ActionResult> {
    return this.requestBuilder
      .request('delete', `/api/classifieds/buckets/${SearchesBucketType.SEEN}/`)
      .data({ classified: id })
      .action()
      .send()
  }

  removeSearchFromBucket(
    bucket: SearchesBucketType,
    id: number | string
  ): Promise<ActionResult> {
    return this.requestBuilder
      .request('delete', `/api/searches/buckets/${bucket}/`)
      .data({ id })
      .action()
      .send()
  }

  async removeSearchFromFavorites(searchId: string): Promise<string | null> {
    const response: AxiosResponse = await this.http.delete(
      `/api/searches/buckets/${SearchesBucketType.FAVORITE}/`,
      {
        data: { search: searchId }
      }
    )

    if (response.status === 404) {
      return null
    }

    const { data: body } = response
    if (!body || !body.message) {
      throw invalidBodyError(body)
    }
    return body.message
  }

  async getRecentSearchesForCategory(
    categoryId: string = CategoryId.CLASSIFIEDS.toString()
  ): Promise<Map<string, Search>> {
    const { data: body } = await this.http.get(
      `/api/searches/buckets/${SearchesBucketType.RECENT}/` +
        qs.stringify(
          { category: categoryId },
          {
            addQueryPrefix: true
          }
        )
    )

    if (!body?.data || !Array.isArray(body.data.searches)) {
      throw invalidBodyError(body)
    }

    return new Map(
      body.data.searches.map((s: any) => [
        s.query_id?.toString(),
        this.formatSearch(s)
      ])
    )
  }

  getFavoriteSearches(
    categoryId: string = CategoryId.CLASSIFIEDS.toString()
  ): Promise<Map<string, Search>> {
    return this.requestBuilder
      .request('get', `/api/searches/buckets/${SearchesBucketType.FAVORITE}/`)
      .params({ category: categoryId })
      .map(body => this.normalizeFavorites(body.data?.searches))
      .send()
  }

  normalizeFavorites(favorites: []) {
    if (!favorites || !Array.isArray(favorites)) {
      throw invalidBodyError(favorites)
    }
    return new Map(
      favorites.map((s: any) => [s.query_id?.toString(), this.formatSearch(s)])
    )
  }

  filterSubscribableSearchIds(searches: SearchOfBucket[]): Set<string> {
    const subscribableSearchIds = new Set<string>()
    for (const search of searches) {
      search.subscribable && subscribableSearchIds.add(search.query_id)
    }
    return subscribableSearchIds
  }

  private formatSearch(s: any): Search {
    return {
      ...toCamelCase(s),
      id: s.query_id && s.query_id.toString(),
      categoryId: s.category_id && s.category_id.toString(),
      creationDate: s.date
    }
  }
}
