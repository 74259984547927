
















































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { Interval } from '~/models/timing/interval'
import { Variant, VariantType } from '~/models/app/variant'
import { ONE_SECOND_MS } from '~/constants/duration'
import { ciExclamationTriangle } from '~/icons/source/solid/exclamation-triangle'
import { ciExclamationTriangle as ciExclamationTriangleRegular } from '~/icons/source/regular/exclamation-triangle'
import { ciTimesCircle } from '~/icons/source/solid/times-circle'
import { ciTimesCircle as ciTimesCircleRegular } from '~/icons/source/regular/times-circle'
import { ciCheckCircle } from '~/icons/source/solid/check-circle'
import { ciCheckCircle as ciCheckCircleRegular } from '~/icons/source/regular/check-circle'
import { ciInfoCircle } from '~/icons/source/solid/info-circle'
import { ciInfoCircle as ciInfoCircleRegular } from '~/icons/source/regular/info-circle'
import { ciExclamationCircle } from '~/icons/source/solid/exclamation-circle'
import { ciExclamationCircle as ciExclamationCircleRegular } from '~/icons/source/regular/exclamation-circle'
import { ciTimes } from '~/icons/source/regular/times'

export default defineComponent({
  props: {
    show: {
      type: [Boolean, Number],
      default: false
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    fade: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String as PropType<VariantType>,
      default: Variant.INFO
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    description: {
      type: String,
      default: '',
      required: false
    },
    outline: {
      type: Boolean,
      default: false,
      required: false
    },
    icon: {
      type: [Object, Boolean],
      default: false
    },
    contentClass: {
      type: [String, Array, Object],
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { show, variant, fade, icon, outline } = toRefs(props)
    const isVisible = ref(Boolean(show.value))
    const countDownVal = ref(0)
    const countDownInterval = ref<Interval | null>(null)
    handleShowValue(show.value)

    function handleDismissed() {
      if (countDownInterval.value) {
        clearInterval(countDownInterval.value)
      }
      emit('dismissed')
      isVisible.value = false
    }
    function countDown() {
      countDownInterval.value = setInterval(() => {
        if (countDownVal.value < 1) {
          clearInterval(countDownInterval.value as Interval)
          isVisible.value = false
        }
        countDownVal.value--

        emit('dismiss-count-down', countDownVal.value)
      }, ONE_SECOND_MS)
    }

    const alertClasses = computed(() => {
      function fadeClasses(): string[] {
        if (fade.value) {
          return ['fade-in']
        }
        return []
      }
      function borderClasses(): string[] {
        if (outline.value) {
          return ['tw-border', 'tw-border-solid']
        }
        return []
      }
      function variantClasses(): string[] {
        switch (variant.value) {
          case Variant.PRIMARY: {
            if (outline.value) {
              return ['tw-text-primary-500', 'tw-border-primary-200']
            }
            return ['tw-text-primary-500', 'tw-bg-primary-100']
          }
          case Variant.SUCCESS: {
            if (outline.value) {
              return ['tw-text-green-500', 'tw-border-green-200']
            }
            return ['tw-text-green-500', 'tw-bg-green-100']
          }
          case Variant.WARNING: {
            if (outline.value) {
              return ['tw-text-amber-500', 'tw-border-amber-200']
            }
            return ['tw-text-amber-500', 'tw-bg-yellow-100']
          }
          case Variant.DANGER: {
            if (outline.value) {
              return ['tw-text-red-400', 'tw-border-red-200']
            }
            return ['tw-text-red-400', 'tw-bg-red-100']
          }
          case Variant.SECONDARY:
          case Variant.LIGHT:
          case Variant.NEUTRAL: {
            if (outline.value) {
              return ['tw-text-grey-600', 'tw-border-grey-400']
            }
            return ['tw-text-grey-600', 'tw-bg-grey-200']
          }
          case Variant.INFO:
          default: {
            if (outline.value) {
              return ['tw-text-blue-500', 'tw-border-blue-200']
            }
            return ['tw-text-blue-500', 'tw-bg-blue-100']
          }
        }
      }

      return [...fadeClasses(), ...variantClasses(), ...borderClasses()]
    })

    const closeButtonHoverClasses = computed(() => {
      function variantClasses(): string[] {
        switch (variant.value) {
          case Variant.PRIMARY: {
            return ['hover:tw-text-primary-900']
          }
          case Variant.SUCCESS: {
            return ['hover:tw-text-green-900']
          }
          case Variant.WARNING: {
            return ['hover:tw-text-amber-900']
          }
          case Variant.DANGER: {
            return ['hover:tw-text-red-800']
          }
          case Variant.NEUTRAL: {
            return ['hover:tw-text-grey-900']
          }
          case Variant.INFO:
          default: {
            return ['hover:tw-text-blue-900']
          }
        }
      }
      return [...variantClasses()]
    })

    onMounted(() => {
      if (typeof show.value === 'number' && show.value > 0) {
        countDown()
      }
    })

    onBeforeUnmount(() => {
      if (countDownInterval.value) {
        clearInterval(countDownInterval.value)
      }
    })

    function handleShowValue(show: boolean | number) {
      if (typeof show === 'boolean') {
        isVisible.value = show
      } else {
        countDownVal.value = show
      }
    }

    watch(show, newShow => {
      handleShowValue(newShow)
    })

    const defaultIcon = computed(() => {
      switch (variant.value) {
        case Variant.WARNING: {
          return outline.value
            ? ciExclamationTriangle
            : ciExclamationTriangleRegular
        }
        case Variant.SUCCESS: {
          return outline.value ? ciCheckCircle : ciCheckCircleRegular
        }
        case Variant.DANGER: {
          return outline.value ? ciTimesCircle : ciTimesCircleRegular
        }
        case Variant.NEUTRAL: {
          return outline.value
            ? ciExclamationCircle
            : ciExclamationCircleRegular
        }
        case Variant.INFO:
        case Variant.PRIMARY:
        default: {
          return outline.value ? ciInfoCircle : ciInfoCircleRegular
        }
      }
    })

    const internalIcon = computed(() => {
      if (icon.value === false) {
        return null
      } else if (icon.value === true) {
        return defaultIcon.value
      }
      return icon.value
    })

    return {
      ciTimes,
      handleDismissed,
      isVisible,
      alertClasses,
      closeButtonHoverClasses,
      internalIcon
    }
  }
})
