export const Variant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  NEUTRAL: 'neutral',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  SUCCESS: 'success',
  WHITE: 'white',
  LIGHT: 'light',
  LINK: 'link',
  TEAL: 'teal',
  BLUE: 'blue',
  BLUE_DARK: 'blue-dark',
  ORANGE: 'orange',
  DARK: 'dark',
  NEW: 'new'
} as const

export const ButtonVariant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  NEUTRAL: 'neutral',
  LIGHT: 'light',
  OFFER: 'offer',
  PARKING: 'parking',
  VIBER: 'viber',
  WHATSAPP: 'whatsapp',
  TURBO: 'turbo',
  SUPER: 'super',
  TOP: 'top',
  FACEBOOK: 'facebook',
  TBI: 'tbi',
  X: 'x',
  LINK: 'link'
} as const

type VariantKeys = keyof typeof Variant
export type VariantType = typeof Variant[VariantKeys]

type ButtonVariantKeys = keyof typeof ButtonVariant
export type ButtonVariantType = typeof ButtonVariant[ButtonVariantKeys]
