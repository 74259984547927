import { inject } from 'tsyringe'
import { Merge } from 'type-fest'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import CSnackbarComponent from '~/components/shared/configurable/snackbar/CSnackbar.vue'
import { Variant } from '~/models/app/variant'
import { Position } from '~/models/app/position'
import { containerScoped } from '~/decorators/dependency-container'
import { SnackbarActionFactory } from '~/factories/snackbar/action'
import { CSnackbarOptions } from '~/models/snackbar'
import LoggerService from '~/services/LoggerService'
import { ISnackbarService } from '~/services/snackbar/ISnackBarService'
import { sleep } from '~/utils/time'
import LocaleMessage = VueI18n.LocaleMessage

const defaultOptions: CSnackbarOptions = {
  variant: undefined,
  time: 4000,
  action: undefined,
  classes: [],
  position: Position.BOTTOM_RIGHT
}

export interface ICSnackbarComponent extends Vue {
  active: boolean
  text: string
  variant: CSnackbarOptions['variant']
  action: CSnackbarOptions['action']
  classes: CSnackbarOptions['classes']
  position: CSnackbarOptions['position']
  show: Function
  hide: Function
}

@containerScoped()
export default class SnackbarService implements ISnackbarService {
  private snackbar: ICSnackbarComponent | undefined
  private timeout: any
  private closing: boolean = false
  constructor(
    @inject(VueI18n) private i18n: VueI18n,
    @inject(LoggerService) private logger: LoggerService,
    @inject(SnackbarActionFactory) private actionFactory: SnackbarActionFactory
  ) {
    if (process.client) {
      const SnackbarComponent = Vue.extend(CSnackbarComponent)
      this.snackbar = new SnackbarComponent()
      this.snackbar.$on('close', (_: any) => this.close())
      this.snackbar.$mount()
      document.body.appendChild(this.snackbar.$el)
    }
  }

  public async show(
    text: string | LocaleMessage,
    {
      variant = defaultOptions.variant,
      time = defaultOptions.time,
      action = defaultOptions.action,
      classes = defaultOptions.classes,
      position = defaultOptions.position
    }: CSnackbarOptions = defaultOptions
  ) {
    if (!this.snackbar) {
      return
    }
    if (this.snackbar.active) {
      this.close()
      this.closing = true
      await sleep(400)
      this.closing = false
    }
    // setting the options and the text
    this.snackbar.text = text as string
    this.snackbar.variant = variant
    this.snackbar.action =
      typeof action === 'function' ? action(this.actionFactory) : action
    this.snackbar.classes = classes
    this.snackbar.position = position
    // setting the snackbar as active, to be displayed
    this.snackbar.show()
    this.timeout = setTimeout(() => {
      this.close()
    }, time)
  }

  private close() {
    if (!this.snackbar) {
      return
    }
    this.snackbar.hide()
    clearTimeout(this.timeout)
  }

  public success(text: string | LocaleMessage, options?: CSnackbarOptions) {
    return (
      this.snackbar &&
      this.show(text, this.generateOptions(Variant.SUCCESS, options))
    )
  }

  public savedChanges() {
    this.snackbar &&
      this.success(this.i18n.t('success_saved_changes') as string, {
        time: 1600
      })
  }

  public saved() {
    this.snackbar &&
      this.success(this.i18n.t('saved::verb') as string, {
        time: 1600
      })
  }

  public error(
    text: string | LocaleMessage | Error,
    options?: Merge<CSnackbarOptions, { log?: boolean }>
  ) {
    if (options?.log) {
      const error = text instanceof Error ? text : new Error(text as string)
      this.logger.captureError(error)
    }
    return (
      this.snackbar &&
      this.show(
        text instanceof Error ? text.message : text,
        this.generateOptions(Variant.DANGER, options)
      )
    )
  }

  public info(text: string | LocaleMessage, options?: CSnackbarOptions) {
    return (
      this.snackbar &&
      this.show(text, this.generateOptions(Variant.INFO, options))
    )
  }

  private generateOptions(variant: string, options?: CSnackbarOptions) {
    return {
      variant,
      action: options && options.action,
      classes: (options && options.classes) || [],
      time: options && options.time,
      position: options && options.position
    }
  }
}
