import {
  Field,
  Fieldset,
  FormCategory,
  FormClassified,
  FormClassifiedInfo
} from '~/models/form/types'
import { toCamelCase } from '~/utils/object'

export function formatFieldset(f: any): Fieldset {
  return {
    humanName: f.human_name,
    name: f.name,
    fields: f.fields.map(formatField),
    order: f.order,
    isCollapsed: f.is_collapsed,
    extras: f.extras
  }
}

export function formatFormClassified(c: any): FormClassified {
  return {
    info: c.info && formatInfo(c.info),
    fieldValues: c.field_values,
    incompleteFieldValues: c.incomplete_field_values,
    id: c.id,
    owner: c.owner
  }
}

export function formatInfo(i: any): FormClassifiedInfo {
  return {
    baseCategory: i.base_category,
    categories: i.categories,
    category: i.category,
    created: i.created,
    deleted: i.deleted,
    deletedDate: i.deleted_date,
    expires: i.expires,
    hidden: i.hidden,
    maxAllowedCategories: i.max_allowed_categories,
    modified: i.modified,
    nonPublic: i.non_public,
    seoUrl: i.seo_url,
    states: i.states,
    title: i.title,
    touch: i.touch,
    unreadMessagesCount: i.unread_messages_count,
    promotion: toCamelCase(i.promotion),
    payment: toCamelCase(i.payment),
    saleRequests: toCamelCase(i.sale_requests)
  }
}

export function formatField(f: any): Field {
  return {
    name: f.name,
    required: f.required,
    specs: f.specs,
    id: f.id,
    disabled: f.disabled,
    humanName: f.human_name,
    type: f.type,
    order: f.order,
    suggestions: f.suggestions
  }
}

export function formatFormCategory(c: any): FormCategory {
  return {
    categoryIds: c.category_ids,
    humanName: c.human_name,
    humanNamePlural: c.human_name_plural,
    id: c.id,
    name: c.name,
    isBase: c.isBase
  }
}
