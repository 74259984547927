import { interopDefault } from '~/router/utils'

export const accountEshopRoutes = [
  {
    path: 'eshop/',
    name: '__account_eshop',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/account/eshop/index.vue' /* webpackChunkName: "pages-shared-account-eshop-index.vue" */
        )
      ),
    meta: {
      scrollTopSmooth: true
    },
    redirect: { name: '__account_eshop_overview' },
    children: [
      {
        path: 'onboarding/',
        name: '__account_eshop_onboarding',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/onboarding.vue' /* webpackChunkName: "pages-shared-account-eshop-onboarding.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'overview/',
        name: '__account_eshop_overview',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/overview.vue' /* webpackChunkName: "pages-shared-account-eshop-overview.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'orders/',
        name: '__account_eshop_orders',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/list.vue' /* webpackChunkName: "pages-shared-account-orders-list.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'order/:orderId/',
        name: '__account_eshop_orders_order_details',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/orders/order.vue' /* webpackChunkName: "pages-shared-account-orders-order.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'products/',
        name: '__account_eshop_products',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/products.vue' /* webpackChunkName: "pages-shared-account-eshop-products.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'shipping-options/',
        name: '__account_eshop_shipping_options',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/shipping-options.vue' /* webpackChunkName: "pages-shared-account-eshop-shipping-options.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'shipping-options/edit/:optionId/',
        name: '__account_eshop_shipping_options_edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/shipping-option.vue' /* webpackChunkName: "pages-shared-account-eshop-shipping-option.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'shipping-options/new/',
        name: '__account_eshop_shipping_options_new',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/shipping-option.vue' /* webpackChunkName: "pages-shared-account-eshop-shipping-option.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true,
          hideTabs: true
        }
      },
      {
        path: 'transactions/',
        name: '__account_eshop_transactions',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/transactions.vue' /* webpackChunkName: "pages-shared-account-eshop-transactions.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'reviews/',
        name: '__account_eshop_reviews',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/reviews/list.vue' /* webpackChunkName: "pages-shared-account-eshop-reviews-list.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'return-policy/',
        name: '__account_eshop_return_policy',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/return-policy.vue' /* webpackChunkName: "pages-shared-account-eshop-return-policy.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      },
      {
        path: 'settings/',
        name: '__account_eshop_settings',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/account/eshop/settings.vue' /* webpackChunkName: "pages-shared-account-eshop-settings.vue" */
            )
          ),
        meta: {
          scrollTopSmooth: true
        }
      }
    ]
  }
]
