export enum ClientFlashMessageType {
  ALREADY_LOGGED_IN = 'already-logged-in',
  NOT_LOGGED_IN = 'not-logged-in',
  GUEST_REGISTER_SUCCESS = 'guest-register-success',
  GUEST_ACTIVATE_SUCCESS = 'guest-activate-success',
  CHANGE_EMAIL_ACTIVATION_SUCCESS = 'change-email-activation-success',
  CHANGE_EMAIL_ACTIVATION_ERROR = 'change-email-activation-error',
  GUEST_ACTIVATE_ERROR = 'guest-activate-error',
  PASSWORD_RESET_SUCCESS = 'password-reset-success',
  PASSWORD_SET_SUCCESS = 'password-set-success',
  PASSWORD_SET_ERROR = 'password-set-error',
  RESTORE_WITH_CODE = 'restore-with-code',
  TOUCH_WITH_CODE = 'touch-with-code',
  MARKETPLACE_ONBOARDING_SUCCESS = 'marketplace-onboarding-success',
  GENERIC_ERROR = 'generic-error'
}

export type ClientFlashMessageData = any

export interface ClientFlashMessage {
  type: ClientFlashMessage
  data?: ClientFlashMessageData
}

export enum BackendFlashMessageVariant {
  OK = 'ok',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DANGER = 'danger'
}

export enum SnackbarVariant {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error'
}

export interface BackendFlashMessage {
  variant: BackendFlashMessageVariant
  message: string // Can be HTML
}
