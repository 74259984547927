import { Context } from '@nuxt/types'
import { UA } from 'nuxt-user-agent/lib/types'
import { Merge } from 'type-fest'
import { ParseSeoUrlResult } from '~/models/seo/url/types'

import { DealerTelephone, Dealer } from '~/models/dealer/types'
import { SubscriptionCounts } from '~/models/search/subscriptions/types'
import { UserType } from '~/models/user/types'
import { Classified } from '~/models/classified/types'
import { Consent, Geolocation } from '~/models/common/types'

import { AdsTargeting, BackendAdsConfig } from '~/models/ads'
import { BackgroundJob } from '~/models/background-jobs/types'

export interface Search {
  id: string
  title: string
  query: string
  categoryId: string
  legacyListingsUrl?: string
  creationDate: string
  isFavorite?: boolean
}
export interface Searchable {
  name: string
  count: number
  children: Searchable[]
  selected: boolean
  value: string | number
  isTop?: boolean
}

export type ClientSideSearcherContext = Merge<
  Pick<Context, 'store' | 'route'>,
  {
    $ua: UA
    $store: Context['store']
  }
>

export type ParsedUrlResult = Merge<
  ParseSeoUrlResult,
  Partial<Pick<ParseSeoUrlResult, 'category'>>
>

export interface SearchOfBucket {
  /* eslint-disable camelcase */
  query_id: string
  name: string | null
  title: string
  date: string
  query: string
  legacy_listings_url: string
  category_id: number
  type: string
  ancestor_category_ids: number[]
  subscribable: boolean
}

export interface SearchTitlePart {
  name: string
  value: string
}

export interface AlternativeSearch {
  tags: string[]
  seoUrl: string
  label: string
}

export enum EnginePowerTypeEnum {
  ENGINE_POWER = 'ENGINE_POWER',
  ENGINE_SIZE = 'ENGINE_SIZE'
}

export enum SearchListResultItemLayout {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}

export enum PurchaseTypesEnum {
  BUY = 'buy',
  PRICE = 'price',
  LEASING = 'leasing',
  INSTALLMENTS = 'with_instalments'
}

export enum InstallmentParamsEnum {
  INSTALLMENT_FROM = 'installment-from',
  INSTALLMENT_TO = 'installment-to',
  DOWNPAYMENT = 'downpayment',
  INSTALLMENTS = 'installments'
}

export const installmentsSortOptions = ['ira', 'ird', 'fia', 'fid']

export interface Phone {
  code: number
  dontShowToPublic: boolean
  telephone: string
  social: object
}
export interface Location {
  city: string
  country: string
  longPrettyName: string
  postcode: string
  prefecture: string
}

export interface ResultsResult {
  newWantedClassifiedUrl?: string
  showInPlotUrl?: string
  isFavorite: boolean
  results: Results
  sellers?: SellersInfo
}

export interface FacetsResult {
  alternativeSearches?: AlternativeSearch[]
  adsTargetings: AdsTargeting[]
  args: object
  canBeSaved: boolean
  category: Category
  categoryIds: number[]
  changeSearchUrl: string
  facets: Facet[]
  isFavorite: boolean
  longTitle: SearchTitlePart[]
  massActions?: MassActions
  mySearchLinks?: {
    isOpen: boolean
    searchLinks: SearchLink[]
  }
  previousSearch: { name: string; search: string; time: number } | null
  newClassifiedUrl: string
  mapSearchUrl?: string
  userProfile: object | null
  searchId: string
  shortTitle: SearchTitlePart[]
  sortFields: SortField[]
  stats: Stats
  title: string
  textSearch: TextSearch
  searchSubscription: {
    subscribable: boolean
    search: {
      notifications: {
        bulkValid: boolean
        instantValid: boolean
      }
    }
    subscriptions: SubscriptionCounts
  }
  userOwnsSearch: boolean
  notMyClassifiedsUrl?: string
}

export interface Results {
  consent?: Consent
  moreResultsButton?: MoreResultsButton
  rows: Classified[]
  savable: boolean
  shareable: boolean
  pagination: Pagination
  ads: BackendAdsConfig
}

export interface SellersResult {
  isFavorite: boolean
  sellers: SearchSellers
}

export interface SearchSellers {
  classifieds: SellerClassifieds
  pagination: Pagination
  rows: SearchSellerRow[]
}

export interface SearchSellerRow extends Dealer {
  classifieds: SearchSellerRowClassified[]
  website?: string
  // eslint-disable-next-line camelcase
  internal_page?: string
}

export interface SearchSellerRowClassified {
  seoUrl: string
  price: string
  id: number
  thumbPattern: string
  title: string
  hasPhotos: boolean
}

export interface Pagination {
  page: number
  pageParam: string
  perPage: number
  total: number
}
export interface MoreResultsButton {
  args: object
  count: number
  name: string
  seoUrl: string
}

export interface Category {
  humanName: string
  humanNamePlural: string
  humanPath: string
  id: number
  name: string
  parentId: number
  pluralName: string
}

export interface MakeModels {
  makes: number[]
  models: number[]
}

export interface Facet {
  category: object & { id: number }
  humanName: string
  isImportant: boolean
  isOpen: boolean
  isSelected: boolean
  name: string
  placeholder: string
  searchables: Searchable[]
  showHandlerName: boolean
  type: string
  urlArgNames: string[]
  values: {
    humanParams: HumanParam[]
    params: []
    selected: any
    value?: any
    typed?: MakeModels
    untyped?: MakeModels
  }
  visible: boolean
  strictValues: boolean
  multicheck?: boolean
  selectedPath?: any
  searchPlaceholder?: string
  variant?: any
  defaultCategory: any
  unitType?: null | string
}

export interface UrlArgValuePair {
  urlarg: string
}

export interface HumanParam {
  isUnselectable: boolean
  name: string
  seoName: string | null
  urlArg: string | null
  value: any
  selectedUrlArgValuePairs?: UrlArgValuePair[]
}

export enum LinkType {
  PROMOTED = 'promoted',
  TOP_LEVEL_CATEGORY = 'top_level_category',
  ALL = 'all',
  DEALS = 'deals',
  BLACK_FRIDAY = 'black_friday',
  TRADES = 'trades',
  XYMA = 'xyma',
  REAL_ESTATE = 'plot',
  RENTALS = 'rentals',
  JOBS = 'jobs',
  WANTED_PARTS = 'wanted_parts'
}
export interface SearchLink {
  categoryName: string
  categoryIds: number[]
  count: number
  label: string
  legacyVType: string
  selected: boolean
  seoUrl: string
  type: LinkType
}

export interface UserProfile {
  website?: string | null
  geolocation?: Geolocation
  telephone1?: DealerTelephone | null
  telephone2?: DealerTelephone | null
  name: string
  type: UserType
  address?: string
  location?: Location
  id: number
  userSince?: number
  email?: string
  managerId?: string | null
  bl?: number
  subProfiles?: UserProfile[]
}

export interface SortField {
  direction: string
  name: string
  order: number
  selected: boolean
  value: string
  values: string[]
}
export interface Stats {
  averagePrice: number
  total: number
}

export interface SellerClassifieds {
  total: number
  privateSellers: {
    count: number
    seoUrl: string
  }
}

export interface SellersInfo {
  total: number
}

export interface FacetDeselectProps {
  handler: Facet
  urlArg: string
  value: any
}
export interface TextSearch {
  searchableCategories: TextSearchSearchableCategory[]
}

export interface TextSearchSearchableCategory {
  humanName: string
  categoryPathIds: number[]
  humanNamePlural: string
  id: number
  seoUrl: string
  adminOnly: boolean
}

export enum MassActionsLockReason {
  NEEDS_SUB = 'needs_sub'
}
export interface MassActions {
  showMassActions: boolean
  locked?: boolean
  lockedReason?: string
  customSearchPages?: MassActionsCustomSearchPages
  maxAllowedActions?: number
  priceChange?: MassActionsFeatureStatus
  remainingActions?: number
  totalActions?: number
  resetTimestamp?: string
  touch?: MassActionsTouch
}

export interface MassActionsCustomSearchPages {
  enabled: boolean
  pages: MassActionsCustomSearchPage[]
}

export interface MassActionsCustomSearchPage {
  id: number
  name: string
}

export interface MassActionsFeatureStatus {
  enabled: boolean
}

export interface MassActionsTouch {
  perPage: MassActionsFeatureStatus
  wholeSearch: MassActionsFeatureStatus
}

export enum MassActionsTypes {
  PAGE = 'page',
  SEARCH = 'search',
  SCHEDULE_RENEWAL_PAGE = 'scheduleRenewalPage'
}

export interface MassActionResult {
  job: BackgroundJob
  remainingActions: number
  totalActions: number
  resetTimestamp: string
}

export interface MassActionResponse {
  data?: MassActionResult
  message: string
  status: number
}
export interface MassPriceChangeOptions {
  direction: string
  changeType: string
  change: number
}
