import { ButtonVariantType, ButtonVariant } from '~/models/app/variant'
import { computed } from '@nuxtjs/composition-api'
import { Size } from '~/models/shared/types'
import { Ref } from '@vue/composition-api'

export function useButtonClasses(
  size: Ref<Size>,
  disabled: Ref<boolean>,
  loading: Ref<boolean>,
  variant: Ref<ButtonVariantType>,
  pressed: Ref<boolean>,
  isLink: Ref<boolean>,
  floating: Ref<boolean>,
  pill: Ref<boolean>,
  outline: Ref<boolean>,
  tertiary: Ref<boolean>,
  circle: Ref<boolean>,
  disabledHover: Ref<boolean>
) {
  const spinnerClasses = computed(() => {
    if (floating.value) {
      return ['tw--left-0.5']
    }
    if (size.value !== 'xs') {
      return ['tw--left-2']
    }
    return []
  })

  const sizeClasses = computed(() => {
    if (floating.value) {
      return [
        'tw-w-14',
        'tw-h-14',
        'tw-flex',
        'tw-items-center',
        'tw-justify-center',
        'tw-text-3xl',
        'tw-shadow'
      ]
    }
    if (circle.value) {
      return [
        'tw-w-10',
        'tw-h-10',
        'tw-text-lg',
        'tw-flex',
        'tw-items-center',
        'tw-justify-center'
      ]
    }

    switch (size.value) {
      case Size.EXTRA_SMALL: {
        return [
          'tw-h-[1.5rem]',
          'tw-min-h-[1.5rem]',
          'tw-px-1',
          'tw-py-0.5',
          'tw-text-sm',
          'tw-rounded',
          'tw-font-medium'
        ]
      }
      case Size.SMALL: {
        return [
          'tw-h-[2rem]',
          'tw-min-h-[2rem]',
          'tw-px-2',
          'tw-py-0',
          'tw-text-base',
          'tw-rounded-md',
          'tw-font-medium'
        ]
      }
      case Size.LARGE: {
        return [
          'tw-h-[3rem]',
          'tw-min-h-[3rem]',
          'tw-px-4',
          'tw-py-0',
          'tw-text-lg',
          'tw-rounded-xl',
          'tw-font-medium'
        ]
      }
      case Size.MEDIUM:
      default: {
        return [
          'tw-h-[2.5rem]',
          'tw-min-h-[2.5rem]',
          'tw-px-3',
          'tw-py-0',
          'tw-text-base',
          'tw-rounded-lg',
          'tw-font-medium'
        ]
      }
    }
  })

  const variantClasses = computed(() => {
    if (disabled.value && !loading.value) {
      return ['tw-bg-grey-300', 'tw-text-grey-600', 'tw-cursor-not-allowed']
    }
    const loadingClasses = loading.value
      ? ['disabled:tw-opacity-70', 'tw-cursor-wait']
      : []

    switch (variant.value) {
      case ButtonVariant.PRIMARY:
      default: {
        if (tertiary.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-primary-50',
                  'hover:tw-text-primary',
                  'hover:tw-border-primary-50'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-primary-100', 'active:tw-border-primary-100']
            : []

          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-primary-100',
                  '!tw-border-primary-100',
                  '!tw-text-primary active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-primary',
            'tw-border-transparent',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        } else if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-primary',
                  'hover:tw-text-white',
                  'hover:tw-border-primary'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-primary-600', 'active:tw-border-primary-600']
            : []

          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-primary-600',
                  '!tw-border-primary-600',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-primary',
            'tw-border-primary',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-primary-600',

                'hover:tw-border-primary-600',

                'hover:tw-text-white'
              ]
            : []

        const active = !loading.value
          ? ['active:tw-bg-primary-700', 'active:tw-border-primary-700']
          : []
        const pressedClasses = pressed.value
          ? ['!tw-bg-primary-700', '!tw-border-primary-700', 'active']
          : []
        return [
          'tw-text-white',
          'tw-bg-primary',
          'tw-border',
          'tw-border-solid',
          'tw-border-primary',
          ...pressedClasses,
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.SECONDARY: {
        if (tertiary.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-blue-50',
                  'hover:tw-text-blue',
                  'hover:tw-border-blue-50'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-blue-100', 'active:tw-border-blue-100']
            : []

          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-blue-100',
                  '!tw-border-blue-100',
                  '!tw-text-blue active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-blue',
            'tw-border-transparent',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        } else if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-blue',
                  'hover:tw-text-white',
                  'hover:tw-border-blue'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-blue-600', 'active:tw-border-blue-600']
            : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-blue-600',
                  '!tw-border-blue-600',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-blue',
            'tw-border-blue',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-blue-600',

                'hover:tw-border-blue-600',

                'hover:tw-text-white'
              ]
            : []

        const active = !loading.value
          ? ['active:tw-bg-blue-700', 'active:tw-border-blue-700']
          : []
        const pressedClasses = pressed.value
          ? ['!tw-bg-blue-700', '!tw-border-blue-700', 'active']
          : []
        return [
          'tw-text-white',
          'tw-bg-blue',
          'tw-border',
          'tw-border-solid',
          'tw-border-blue',
          ...pressedClasses,
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }

      case ButtonVariant.SUCCESS: {
        if (tertiary.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-green-50',
                  'hover:tw-text-green',
                  'hover:tw-border-green-50'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-green-100', 'active:tw-border-green-100']
            : []

          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-green-100',
                  '!tw-border-green-100',
                  '!tw-text-green active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-green',
            'tw-border-transparent',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        } else if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-green',
                  'hover:tw-text-white',
                  'hover:tw-border-green'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-green-600', 'active:tw-border-green-600']
            : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-green-600',
                  '!tw-border-green-600',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-green',
            'tw-border-green',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-green-600',

                'hover:tw-border-green-600',

                'hover:tw-text-white'
              ]
            : []

        const active = !loading.value
          ? ['active:tw-bg-green-700', 'active:tw-border-green-700']
          : []
        const pressedClasses = pressed.value
          ? ['!tw-bg-green-700', '!tw-border-green-700', 'active']
          : []
        return [
          'tw-text-white',
          'tw-bg-green',
          'tw-border',
          'tw-border-solid',
          'tw-border-green',
          ...pressedClasses,
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }

      case ButtonVariant.DANGER: {
        if (tertiary.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-red-50',
                  'hover:tw-text-red-400',
                  'hover:tw-border-red-50'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-red-100', 'active:tw-border-red-100']
            : []

          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-red-100',
                  '!tw-border-red-100',
                  '!tw-text-red-400 active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-red-400',
            'tw-border-transparent',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        } else if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-red-400',
                  'hover:tw-text-white',
                  'hover:tw-border-red-400'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-red-500', 'active:tw-border-red-500']
            : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-red-500',
                  '!tw-border-red-500',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-red-400',
            'tw-border-red-400',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-red-500',
                'hover:tw-border-red-500',
                'hover:tw-text-white'
              ]
            : []

        const active = !loading.value
          ? ['active:tw-bg-red-600', 'active:tw-border-red-600']
          : []
        const pressedClasses = pressed.value
          ? ['!tw-bg-red-600', '!tw-border-red-600', 'active']
          : []
        return [
          'tw-text-white',
          'tw-bg-red-400',
          'tw-border',
          'tw-border-solid',
          'tw-border-red-400',
          ...pressedClasses,
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.WARNING: {
        if (tertiary.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-amber-50',
                  'hover:tw-text-amber-500',
                  'hover:tw-border-amber-50'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-amber-100', 'active:tw-border-amber-100']
            : []

          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-amber-100',
                  '!tw-border-amber-100',
                  '!tw-text-amber-500 active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-amber-500',
            'tw-border-transparent',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        } else if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-amber-500',
                  'hover:tw-text-white',
                  'hover:tw-border-amber-500'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-amber-600', 'active:tw-border-amber-600']
            : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-amber-600',
                  '!tw-border-amber-600',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-amber-500',
            'tw-border-amber-500',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-amber-600',
                'hover:tw-border-amber-600',
                'hover:tw-text-white'
              ]
            : []

        const active = !loading.value
          ? ['active:tw-bg-amber-700', 'active:tw-border-amber-700']
          : []
        const pressedClasses = pressed.value
          ? ['!tw-bg-amber-700', '!tw-border-amber-700', 'active']
          : []
        return [
          'tw-text-white',
          'tw-bg-amber-500',
          'tw-border',
          'tw-border-solid',
          'tw-border-amber-500',
          ...pressedClasses,
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.NEUTRAL: {
        if (tertiary.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-grey-50',
                  'hover:tw-text-grey-600',
                  'hover:tw-border-grey-50'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-grey-100', 'active:tw-border-grey-100']
            : []

          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-grey-100',
                  '!tw-border-grey-100',
                  '!tw-text-grey-600 active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-grey-600',
            'tw-border-transparent',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        } else if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-grey-600',
                  'hover:tw-text-white',
                  'hover:tw-border-grey-600'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-grey-700', 'active:tw-border-grey-700']
            : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-grey-700',
                  '!tw-border-grey-700',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-grey-600',
            'tw-border-grey-600',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-grey-700',
                'hover:tw-border-grey-700',
                'hover:tw-text-white'
              ]
            : []

        const active = !loading.value
          ? ['active:tw-bg-grey-800', 'active:tw-border-grey-800']
          : []
        const pressedClasses = pressed.value
          ? ['!tw-bg-grey-800', '!tw-border-grey-800', 'active']
          : []
        return [
          'tw-text-white',
          'tw-bg-grey-600',
          'tw-border',
          'tw-border-solid',
          'tw-border-grey-600',
          ...pressedClasses,
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.LIGHT: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-grey-300',
                  'hover:tw-text-grey-900',
                  'hover:tw-border-grey-300'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-grey-400', 'active:tw-border-grey-400']
            : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-grey-400',
                  '!tw-border-grey-400',
                  '!tw-text-grey-900 active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-grey-900',
            'tw-border-grey-400',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }
        const hover =
          !loading.value && !disabledHover.value
            ? ['hover:tw-bg-grey-300', 'hover:tw-border-grey-300']
            : []

        const active = !loading.value
          ? ['active:tw-bg-grey-400', 'active:tw-border-grey-400']
          : []
        const pressedClasses = pressed.value
          ? ['!tw-bg-grey-400', '!tw-border-grey-400', 'active']
          : []
        return [
          'tw-text-grey-900',
          'tw-bg-grey-200',
          'tw-border',
          'tw-border-solid',
          'tw-border-grey-200',
          ...pressedClasses,
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.OFFER: {
        if (tertiary.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-offer-green-50',
                  'hover:tw-text-offer-green-500',
                  'hover:tw-border-offer-green-50'
                ]
              : []

          const active = !loading.value
            ? [
                'active:tw-bg-offer-green-100',
                'active:tw-border-offer-green-100'
              ]
            : []

          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-offer-green-100',
                  '!tw-border-offer-green-100',
                  '!tw-text-offer-green-500 active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-offer-green-500',
            'tw-border-transparent',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        } else if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-offer-green-500',
                  'hover:tw-text-white',
                  'hover:tw-border-offer-green-500'
                ]
              : []

          const active = !loading.value
            ? [
                'active:tw-bg-offer-green-600',
                'active:tw-border-offer-green-600'
              ]
            : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-offer-green-600',
                  '!tw-border-offer-green-600',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-offer-green-500',
            'tw-border-offer-green-500',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-offer-green-600',
                'hover:tw-border-offer-green-600',
                'hover:tw-text-white'
              ]
            : []

        const active = !loading.value
          ? ['active:tw-bg-offer-green-700', 'active:tw-border-offer-green-700']
          : []
        const pressedClasses = pressed.value
          ? ['!tw-bg-offer-green-700', '!tw-border-offer-green-700', 'active']
          : []
        return [
          'tw-text-white',
          'tw-bg-offer-green-500',
          'tw-border',
          'tw-border-solid',
          'tw-border-offer-green-500',
          ...pressedClasses,
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.PARKING: {
        if (pressed.value) {
          const active = !loading.value
            ? ['active:tw-bg-blue-800', 'active:tw-border-blue-800']
            : []

          return [
            'tw-text-white',
            'tw-bg-blue-700',
            'tw-border',
            'tw-border-solid',
            'tw-border-blue-700',
            ...loadingClasses,
            ...active
          ]
        }

        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-blue-300',
                  'hover:tw-text-white',
                  'hover:tw-border-blue-300'
                ]
              : []

          const active = !loading.value
            ? ['active:tw-bg-blue-700', 'active:tw-border-blue-700']
            : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-blue-700',
                  '!tw-border-blue-700',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-blue-700',
            'tw-border-blue-700',
            ...pressedClasses,
            ...hover,
            ...loadingClasses,
            ...active
          ]
        }

        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-blue-300',
                'hover:tw-border-blue-300',
                'hover:tw-text-white'
              ]
            : []

        const active = !loading.value
          ? ['active:tw-bg-blue-700', 'active:tw-border-blue-700']
          : []

        return [
          'tw-text-white',
          'tw-bg-blue-200',
          'tw-border',
          'tw-border-solid',
          'tw-border-blue-200',
          ...loadingClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.VIBER: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-viber-dark',
                  'hover:tw-text-white',
                  'hover:tw-border-viber-dark'
                ]
              : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-viber-dark',
                  '!tw-border-viber-dark',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-viber',
            'tw-border-viber',
            ...pressedClasses,
            ...hover,
            ...loadingClasses
          ]
        }

        const active = !loading.value
          ? ['active:tw-bg-viber-dark', 'active:tw-border-viber']
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-viber-dark',
                'hover:tw-border-viber-dark',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !loading.value
            ? ['!tw-bg-viber-dark', '!tw-border-viber-dark', 'active']
            : []
        return [
          'tw-bg-viber',
          'tw-text-white',
          ...loadingClasses,
          ...pressedClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.WHATSAPP: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-whatsapp',
                  'hover:tw-text-white',
                  'hover:tw-border-whatsapp'
                ]
              : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-whatsapp-dark',
                  '!tw-border-whatsapp-dark',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-whatsapp',
            'tw-border-whatsapp',
            ...pressedClasses,
            ...hover,
            ...loadingClasses
          ]
        }
        const active = !loading.value
          ? ['active:tw-bg-whatsapp-dark', 'active:tw-border-whatsapp-dark']
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-whatsapp-dark',
                'hover:tw-border-whatsapp-dark',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !loading.value
            ? ['!tw-bg-whatsapp-dark', '!tw-border-whatsapp-dark', 'active']
            : []
        return [
          'tw-bg-whatsapp',
          'tw-text-white',
          ...loadingClasses,
          ...pressedClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.TURBO: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-promote-turbo',
                  'hover:tw-text-white',
                  'hover:tw-border-promote-turbo'
                ]
              : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-promote-turbo-dark',
                  '!tw-border-promote-turbo-dark',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-promote-turbo',
            'tw-border-promote-turbo',
            ...pressedClasses,
            ...hover,
            ...loadingClasses
          ]
        }
        const active = !loading.value
          ? [
              'active:tw-bg-promote-turbo-dark',
              'active:tw-border-promote-turbo-dark'
            ]
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-promote-turbo-dark',
                'hover:tw-border-promote-turbo-dark',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !loading.value
            ? [
                '!tw-bg-promote-turbo-dark',
                '!tw-border-promote-turbo-dark',
                'active'
              ]
            : []
        return [
          'tw-bg-promote-turbo',
          'tw-text-white',
          ...loadingClasses,
          ...pressedClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.SUPER: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-green-500',
                  'hover:tw-text-white',
                  'hover:tw-border-green-500'
                ]
              : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-green-600',
                  '!tw-border-green-600',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-green-500',
            'tw-border-green-500',
            ...pressedClasses,
            ...hover,
            ...loadingClasses
          ]
        }
        const active = !loading.value
          ? ['active:tw-bg-green-600', 'active:tw-border-green-600']
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-green-600',
                'hover:tw-border-green-600',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !loading.value
            ? ['!tw-bg-green-600', '!tw-border-green-600', 'active']
            : []
        return [
          'tw-bg-green-500',
          'tw-text-white',
          ...loadingClasses,
          ...pressedClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.TOP: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-blue-600',
                  'hover:tw-text-white',
                  'hover:tw-border-blue-600'
                ]
              : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-blue-700',
                  '!tw-border-blue-700',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-blue-600',
            'tw-border-blue-600',
            ...pressedClasses,
            ...hover,
            ...loadingClasses
          ]
        }
        const active = !loading.value
          ? ['active:tw-bg-blue-700', 'active:tw-border-blue-700']
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-blue-700',
                'hover:tw-border-blue-700',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !loading.value
            ? ['!tw-bg-blue-700', '!tw-border-blue-700', 'active']
            : []
        return [
          'tw-bg-blue-600',
          'tw-text-white',
          ...loadingClasses,
          ...pressedClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.FACEBOOK: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-facebook',
                  'hover:tw-text-white',
                  'hover:tw-border-facebook'
                ]
              : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-facebook-dark',
                  '!tw-border-facebook-dark',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-facebook',
            'tw-border-facebook',
            ...pressedClasses,
            ...hover,
            ...loadingClasses
          ]
        }
        const active = !loading.value
          ? ['active:tw-bg-facebook-dark', 'active:tw-border-facebook-dark']
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-facebook-dark',
                'hover:tw-border-facebook-dark',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !loading.value
            ? ['!tw-bg-facebook-dark', '!tw-border-facebook-dark', 'active']
            : []
        return [
          'tw-bg-facebook',
          'tw-text-white',
          ...loadingClasses,
          ...pressedClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.X: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? ['hover:tw-bg-x', 'hover:tw-text-white', 'hover:tw-border-x']
              : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-grey-900',
                  '!tw-border-grey-900',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-x',
            'tw-border-x',
            ...pressedClasses,
            ...hover,
            ...loadingClasses
          ]
        }
        const active = !loading.value
          ? ['active:tw-bg-grey-900', 'active:tw-border-grey-900']
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-grey-900',
                'hover:tw-border-grey-900',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !loading.value
            ? ['!tw-bg-grey-900', '!tw-border-grey-900', 'active']
            : []
        return [
          'tw-bg-x',
          'tw-text-white',
          ...loadingClasses,
          ...pressedClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.TBI: {
        if (outline.value) {
          const hover =
            !loading.value && !disabledHover.value
              ? [
                  'hover:tw-bg-tbi',
                  'hover:tw-text-white',
                  'hover:tw-border-tbi'
                ]
              : []
          const pressedClasses =
            pressed.value && !loading.value
              ? [
                  '!tw-bg-tbi-dark',
                  '!tw-border-tbi-dark',
                  '!tw-text-white active'
                ]
              : []

          return [
            'tw-bg-transparent',
            'tw-border',
            'tw-border-solid',
            'tw-text-tbi',
            'tw-border-tbi',
            ...pressedClasses,
            ...hover,
            ...loadingClasses
          ]
        }
        const active = !loading.value
          ? ['active:tw-bg-tbi-dark', 'active:tw-border-tbi-dark']
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-bg-tbi-dark',
                'hover:tw-border-tbi-dark',
                'hover:tw-text-white'
              ]
            : []
        const pressedClasses =
          pressed.value && !loading.value
            ? ['!tw-bg-tbi-dark', '!tw-border-tbi-dark', 'active']
            : []
        return [
          'tw-bg-tbi',
          'tw-text-white',
          ...loadingClasses,
          ...pressedClasses,
          ...hover,
          ...active
        ]
      }
      case ButtonVariant.LINK: {
        const active = !loading.value
          ? ['active:tw-text-primary-900', 'active:tw-underline']
          : []
        const hover =
          !loading.value && !disabledHover.value
            ? [
                'hover:tw-text-primary-600',
                'hover:tw-underline',
                'hover:tw-text-primary-600'
              ]
            : []
        return ['tw-bg-transparent', 'tw-text-primary', ...hover, ...active]
      }
    }
  })

  const linkClasses = computed(() => {
    if (isLink.value) {
      return 'tw-inline-block tw-cursor-pointer'
    }
    return null
  })

  const positionClasses = computed(() => {
    if (floating.value) {
      return ['tw-fixed', 'tw-right-6', 'tw-bottom-6']
    }
    return ['tw-relative']
  })

  const roundnessClasses = computed(() => {
    if (floating.value || circle.value) {
      return ['tw-rounded-full']
    } else if (pill.value) {
      return ['!tw-rounded-[50rem]']
    }
    // else it will be handled by the sizeClasses
    return []
  })

  return {
    sizeClasses,
    variantClasses,
    linkClasses,
    positionClasses,
    roundnessClasses,
    spinnerClasses
  }
}
