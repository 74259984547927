import { Key } from '~/constants/keyboard'
import { getStaticUrl } from '~/utils/static'

export function getParentPosition(parentEl: any, dropdownElem = null as any) {
  if (!parentEl) {
    return
  }

  if (parentEl.$el) {
    parentEl = parentEl.$el
  }

  const container = dropdownElem ? getNearestRelativeParent(dropdownElem) : null

  if (container && container.tagName !== 'body') {
    // container mode
    // used when the portal's nearest relative parent is not the body but some other container
    const {
      left: parentLeft,
      top: parentTop
    } = parentEl.getBoundingClientRect()

    const {
      left: containerLeft,
      top: containerTop
    } = container.getBoundingClientRect()
    const relativeTop = Math.abs(parentTop - containerTop)
    let topToGo = container.scrollTop + relativeTop + parentEl.clientHeight
    let prefferedOpenDirection = 'below'

    if (typeof container !== 'undefined' && dropdownElem) {
      if (dropdownElem.$el) {
        dropdownElem = dropdownElem.$el
      }

      const e =
        container.clientHeight - (relativeTop + dropdownElem.offsetHeight)

      if (e > 0) {
        prefferedOpenDirection = 'below'
      } else {
        prefferedOpenDirection = 'above'
      }

      if (prefferedOpenDirection === 'above') {
        topToGo = container.scrollTop + relativeTop - dropdownElem.offsetHeight
      }
    }

    return {
      style: {
        left: Number(window.scrollX + parentLeft - containerLeft) + 'px',
        top: Number(topToGo) + 'px',
        minWidth: parentEl.clientWidth + 'px',
        maxWidth: 3 * parentEl.clientWidth + 'px'
      },
      openDirection: prefferedOpenDirection
    }
  }

  const { left: rectLeft, top: rectTop } = parentEl.getBoundingClientRect()
  let topToGo = window.scrollY + rectTop + parentEl.clientHeight
  let prefferedOpenDirection = 'below'

  if (typeof window !== 'undefined' && dropdownElem) {
    if (dropdownElem.$el) {
      dropdownElem = dropdownElem.$el
    }

    const e = window.innerHeight - (rectTop + dropdownElem.offsetHeight)

    if (e > 0) {
      prefferedOpenDirection = 'below'
    } else {
      prefferedOpenDirection = 'above'
    }

    if (prefferedOpenDirection === 'above') {
      topToGo = window.scrollY + rectTop - dropdownElem.offsetHeight
    }
  }

  return {
    style: {
      left: Number(window.scrollX + rectLeft) + 'px',
      top: Number(topToGo) + 'px',
      minWidth: parentEl.clientWidth + 'px',
      maxWidth: 3 * parentEl.clientWidth + 'px'
    },
    openDirection: prefferedOpenDirection
  }
}

function getNearestRelativeParent(element: HTMLElement) {
  let parent: Node | null = element.parentNode

  while (parent) {
    const position = window.getComputedStyle(<Element>parent).position
    if (position === 'relative') {
      return parent as HTMLElement
    }
    parent = parent.parentNode
  }

  return null // No positioned parent found
}

export function elementContentIsOverflowing(el: Element) {
  return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight
}

export function elementIsOverflownInParent(
  el: HTMLElement,
  parentEl: HTMLElement
) {
  let overflowDirection = 'up'

  const overflowBottom =
    el.offsetTop + el.offsetHeight > parentEl.clientHeight + parentEl.scrollTop

  const overflowTop = el.offsetTop < parentEl.scrollTop

  if (overflowBottom || overflowTop) {
    if (overflowBottom) {
      overflowDirection = 'down'
    }
    return overflowDirection
  }

  return false
}

export function isElementInViewport(
  element: HTMLElement,
  partiallyVisible = false
): boolean {
  const { top, left, bottom, right } = element.getBoundingClientRect()
  const { innerHeight, innerWidth } = window

  if (!partiallyVisible) {
    return top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
  }

  return (
    (top > 0 && top < innerHeight) ||
    (bottom > 0 && bottom < innerHeight) ||
    (left > 0 && left < innerWidth) ||
    (right > 0 && right < innerWidth)
  )
}

export function copyTextToClipboard(text: string) {
  const el = document.createElement('textarea')
  el.value = text
  el.id = 'copy_dummy'
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export function imageToDataURL(img: ImageBitmap) {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height

  const ctx = canvas.getContext('2d')
  ctx && ctx.drawImage(img, 0, 0)

  return canvas.toDataURL('image/png')
}

export function elementIsVisibleInViewport(el: HTMLElement | null) {
  if (!el) {
    return
  }
  const rect = el.getBoundingClientRect()

  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function changeFaviconNotification(hasNotifications: boolean) {
  if (process.server) {
    // eslint-disable-next-line no-console
    console.warn(
      'Cannot call function without the DOM. This was called on the server'
    )
    return
  }

  const siteName: string = process.env.APP_NAME || 'car'

  // defaults to car icon
  let notificationFavicon =
    'https://static.car.gr/static/favicon/favicon_notification.ico?v=2'
  let originalFavicon = 'https://static.car.gr/static/favicon/favicon.ico?v=2'

  if (siteName === 'plot') {
    notificationFavicon =
      'https://www.plot.gr/static/images/favicons/favicon_notification.ico?v=2'
    originalFavicon =
      'https://www.plot.gr/static/images/favicons/favicon.ico?v=2'
  }

  if (hasNotifications) {
    changeFavicon(notificationFavicon)
    const ping = new Audio(getStaticUrl('/nuxt-static/audio/ping.mp3'))
    try {
      ping.play()
    } catch (_e) {}
  } else {
    changeFavicon(originalFavicon)
  }
}

function changeFavicon(src: string) {
  const link = document.createElement('link')
  const oldLink = document.getElementById('favicon')
  link.id = 'favicon'
  link.rel = 'shortcut icon'
  link.href = src
  if (oldLink) {
    document.head.removeChild(oldLink)
  }
  document.head.appendChild(link)
}

export function downloadFile(
  fileName: string,
  content: any,
  mimeType = 'text/plain'
) {
  const blob = new Blob([content], { type: mimeType })
  const a = document.createElement('a')
  const url = URL.createObjectURL(blob)
  a.setAttribute('href', url)
  a.setAttribute('download', fileName)
  a.click()
}

export function elementLargerThanScreen(elem: HTMLElement): boolean {
  if (process.client && elem?.offsetHeight) {
    let windowHeight = window.innerHeight
    const header = document.getElementById('header-section')
    if (header) {
      windowHeight -= header.offsetHeight
    }
    const elemHeight =
      elem.offsetHeight +
      parseInt(window.getComputedStyle(elem).getPropertyValue('margin-top')) +
      parseInt(window.getComputedStyle(elem).getPropertyValue('margin-bottom'))
    return elemHeight > windowHeight
  }
  return true // so it defaults to top
}

export function preventNonNumericKeyboardEvent(e: KeyboardEvent): void {
  if (e.isComposing || e.keyCode === 229) {
    return
  }
  if (
    // Some generic keys are allowed
    [
      Key.DELETE,
      Key.BACKSPACE,
      Key.ESCAPE,
      Key.ENTER,
      Key.END,
      Key.HOME,
      Key.ARROW_LEFT,
      Key.ARROW_RIGHT
    ].includes(e.key as Key) ||
    // Allow Ctrl + A
    ((e.key === Key.A || e.key === Key.A_CAPS) && (e.ctrlKey || e.metaKey)) ||
    // Allow Ctrl + X
    ((e.key === Key.X || e.key === Key.X_CAPS) && (e.ctrlKey || e.metaKey))
  ) {
    return
  }
  // Ensure that it is a number and stop the keypress
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    e.preventDefault()
  }
}
