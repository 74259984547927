




























import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@nuxtjs/composition-api'
import { useId } from '~/compositions/id'

export default defineComponent({
  model: {
    prop: 'checked',
    event: 'input'
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    checked: {
      type: [String, Number],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    large: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: '',
      required: false
    },
    labelClass: {
      type: String,
      default: '',
      required: false
    },
    variant: {
      type: String as PropType<'primary', 'blue'>,
      default: 'primary'
    }
  },
  setup(props, { emit }) {
    const { id, checked, large, disabled, inputClass, labelClass } = toRefs(
      props
    )

    const { createRandomId } = useId()
    const internalId = id.value ?? createRandomId()

    const internalValue = computed({
      get() {
        return checked.value
      },
      set(value) {
        emit('input', value)
        emit('change', value)
      }
    })

    const radioClasses = computed(() => {
      const classes = []

      if (large.value) {
        classes.push('tw-h-6 tw-w-6')
      } else {
        classes.push('tw-h-5 tw-w-5 lg:tw-h-4 lg:tw-w-4')
      }

      if (disabled.value) {
        classes.push(
          'tw-cursor-not-allowed checked:tw-opacity-50 tw-border-grey-300'
        )
      } else {
        classes.push('tw-cursor-pointer tw-border-grey-400')

        if (props.variant === 'blue') {
          classes.push('group-hover:tw-border-blue-500 focus:tw-ring-blue-500')
        } else {
          classes.push('group-hover:tw-border-primary focus:tw-ring-primary')
        }
      }

      if (props.variant === 'blue') {
        classes.push('tw-text-blue-500')
      } else {
        classes.push('tw-text-primary')
      }

      return [inputClass.value, ...classes]
    })

    const labelClasses = computed(() => {
      const classes = []

      if (large.value) {
        classes.push('tw-text-lg')
      } else {
        classes.push('tw-text-base')
      }

      if (disabled.value) {
        classes.push('tw-text-grey-500 tw-cursor-not-allowed')
      } else {
        classes.push('tw-cursor-pointer')

        if (props.variant === 'blue') {
          classes.push('group-hover:tw-text-blue-500')
        } else {
          classes.push('group-hover:tw-text-primary')
        }
      }

      return [labelClass.value, ...classes]
    })

    return {
      internalId,
      internalValue,
      radioClasses,
      labelClasses
    }
  }
})
