import { Option } from '~/models/shared/types'
import { FormConfig } from '~/models/form/types'
import { CategoryId } from '~/models/category/types'
import {
  JOBS_FORM_CONFIG,
  PARTS_XYMA_FORM_CONFIG,
  PLOT_FORM_CONFIG,
  RENTALS_FORM_CONFIG,
  VEHICLES_FORM_CONFIG,
  WANTED_PARTS_FORM_CONFIG
} from '~/constants/form/config'
import {
  DynamicFormFieldValues,
  DynamicFormSchema
} from '~/models/dynamic-form/schema'

export function computeFieldState(error: string | null) {
  if (error) {
    return false
  }
  return null
}

export function mapBackendOptionToOption(backendOption: any): Option {
  return {
    text: backendOption.name?.toString() || '',
    value: backendOption.value
  }
}

export function arraySchemaToObj(
  schema: DynamicFormSchema
): DynamicFormFieldValues {
  return schema.reduce((acc: Record<any, any>, field) => {
    acc[field.name] = field
    return acc
  }, {})
}

export function getFormConfig(rootCategoryId: number): FormConfig {
  switch (rootCategoryId) {
    case CategoryId.JOBS: {
      return JOBS_FORM_CONFIG
    }
    case CategoryId.VEHICLES: {
      return VEHICLES_FORM_CONFIG
    }
    case CategoryId.XYMA: {
      return PARTS_XYMA_FORM_CONFIG
    }
    case CategoryId.PARTS: {
      return PARTS_XYMA_FORM_CONFIG
    }
    case CategoryId.RENTALS: {
      return RENTALS_FORM_CONFIG
    }
    case CategoryId.PLOT: {
      return PLOT_FORM_CONFIG
    }
    case CategoryId.WANTED_PARTS: {
      return WANTED_PARTS_FORM_CONFIG
    }
    default: {
      return {}
    }
  }
}
