import { MutationTree } from 'vuex'
import { UserState } from './state'
import {
  SET_AVATAR,
  SET_USER_DATA,
  SET_USER_EXTRA_DATA,
  SET_CREDITS,
  SET_USER_NOTIFICATION_CENTER_DATA,
  SET_USER_NOTIFICATION_CENTER_LOADING_STATE,
  CLEAR_NOTIFICATION_CENTER_DATA,
  TRANSFER_ROGUE_CLASSIFIED,
  INCREASE_UNREAD_NOTIFICATIONS,
  DECREASE_UNREAD_NOTIFICATIONS,
  INCREASE_UNREAD_MESSAGES,
  DECREASE_UNREAD_MESSAGES,
  READ_ALL_CURRENT_NOTIFICATION_CENTER_ROWS,
  ADD_NOTIFICATION_CENTER_ROW,
  SET_HAS_PARKED,
  READ_TICKETS,
  SET_HAS_SALE_REQUESTS_ITEMS
} from './mutation-types'
import {
  UserCredits,
  UserNotificationsCenter,
  UserExtrasResult,
  AuthUserResult
} from '~/models/user/types'
import { Pagination } from '~/models/search/types'
import { NotificationEvent } from '~/models/notification-center/types'
import { DEFAULT_NUMBER_OF_RESULTS_PER_PAGE } from '~/constants/search/defaults'

export default {
  [SET_USER_DATA](state, userData: AuthUserResult) {
    state.username = userData.username
    state.name = userData.name
    state.avatar = userData.avatar
    state.id = userData.id
    state.permissions = userData.permissions
    state.properties = userData.properties
    state.type = userData.type
    state.username = userData.username
    state.location = userData.location
    state.credits = userData.credits
    state.featureToggles = userData.featureToggles
    state.referenceCode = userData.referenceCode
    state.isPayingUser = userData.isPayingUser
    state.accessToggles = userData.accessToggles
    state.hasPublicDealers = userData.hasPublicDealers
    state.subscription = userData.subscription
    state.stripeSubscription = userData.stripeSubscription
    state.externalIds = userData.externalIds
    state.sdmu = userData.sdmu
    state.hasCompleteProfile = userData.hasCompleteProfile
    state.inHouseConfig = userData.inHouseConfig
    state.notificationRelayUrl = userData.notificationRelayUrl
    state.telephone = userData.telephone
    state.email = userData.email
    state.firstName = userData.firstName
    state.lastName = userData.lastName
    state.marketplaceIds = userData.marketplaceIds

    if (userData.dealerIds) {
      state.dealerIds = userData.dealerIds
    }
  },
  [SET_AVATAR](state, avatar: string) {
    state.avatar = avatar
  },
  [SET_USER_EXTRA_DATA](state, userExtraData: UserExtrasResult) {
    state.unreadNotifications = userExtraData.unreadNotifications
    state.unreadMessages = userExtraData.unreadMessages
    state.unreadParkingNotifications = userExtraData.unreadParkingNotifications
    state.unreadTradesNotifications = userExtraData.unreadTradesNotifications
    state.myClassifieds = userExtraData.myClassifieds
    state.xmlIssues = userExtraData.xmlIssues
    state.hasTickets = userExtraData.hasTickets
    state.invoicesCount = userExtraData.invoicesCount
    state.outletsCount = userExtraData.outletsCount
    state.rogueClassifiedsCount = userExtraData.rogueClassifiedsCount
    state.rogueRequirements = userExtraData.rogueRequirements
    state.auditRequestsCount = userExtraData.auditRequestsCount
    state.hasParked = userExtraData.hasParked
    state.saleRequests = userExtraData.saleRequests
    state.marketplace = userExtraData.marketplace
  },
  [SET_CREDITS](state, credits: UserCredits) {
    state.credits = credits
  },
  [READ_TICKETS](state) {
    state.hasTickets = false
  },
  [SET_USER_NOTIFICATION_CENTER_DATA](
    state,
    notificationsCenterData: UserNotificationsCenter
  ) {
    state.notificationsCenter = {
      ...state.notificationsCenter,
      ...notificationsCenterData
    }
  },
  [TRANSFER_ROGUE_CLASSIFIED](state) {
    if (state.rogueClassifiedsCount && state.rogueClassifiedsCount > 0)
      state.rogueClassifiedsCount = state.rogueClassifiedsCount - 1
  },
  [CLEAR_NOTIFICATION_CENTER_DATA](state) {
    state.notificationsCenter = {
      loading: true,
      notifications: {
        pagination: {
          perPage: DEFAULT_NUMBER_OF_RESULTS_PER_PAGE,
          total: 0
        } as Pagination,
        rows: [] as Array<NotificationEvent>
      },
      notificationTypes: []
    }
  },
  [SET_USER_NOTIFICATION_CENTER_LOADING_STATE](state, loading: boolean) {
    state.notificationsCenter.loading = loading
  },
  [INCREASE_UNREAD_NOTIFICATIONS](state, increaseBy: number = 1) {
    state.unreadNotifications += increaseBy
  },
  [DECREASE_UNREAD_NOTIFICATIONS](state, decreaseBy: number = 1) {
    if (state.unreadNotifications >= decreaseBy) {
      state.unreadNotifications -= decreaseBy
    }
  },
  [INCREASE_UNREAD_MESSAGES](state, increaseBy: number = 1) {
    state.unreadMessages += increaseBy
  },
  [DECREASE_UNREAD_MESSAGES](state, decreaseBy: number = 1) {
    state.unreadMessages -= decreaseBy
  },
  [READ_ALL_CURRENT_NOTIFICATION_CENTER_ROWS](state) {
    if (state.notificationsCenter?.notifications?.rows?.length) {
      for (
        let i = 0;
        i < state.notificationsCenter.notifications.rows.length;
        i++
      ) {
        state.notificationsCenter.notifications.rows[i].isRead = true
      }
    }
  },
  [ADD_NOTIFICATION_CENTER_ROW](state, row: NotificationEvent) {
    if (state.notificationsCenter?.notifications?.rows) {
      state.notificationsCenter.notifications.rows.unshift(row)
    }
  },
  [SET_HAS_PARKED](state, value: boolean) {
    state.hasParked = value
  },
  [SET_HAS_SALE_REQUESTS_ITEMS](state, hasItems: boolean) {
    if (!state.saleRequests) {
      return
    }

    state.saleRequests.hasItems = hasItems
  }
} as MutationTree<UserState>
