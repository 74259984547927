import { HttpStatus } from '~/constants/http'
import { PaymentsInfoResult } from '~/models/classified/paid/types'
import { Classified, ClassifiedStates } from '~/models/classified/types'
import { Category } from '~/models/search/types'
import { Promotion } from '~/models/classified/promoted'
import { FieldOptions } from '~/store/modules/shared/classifieds/form/actions'

export interface FormCategory {
  categoryIds: number[]
  humanName: string
  humanNamePlural: string
  id: number
  name: string
  isBase: boolean
}

export interface Fieldset {
  humanName: string
  name: string
  fields: Field[]
  order: object
  isCollapsed: boolean
  extras: object
}
export interface Field {
  name: string
  required: boolean
  specs: object
  id: number
  disabled: boolean
  humanName: string
  type: string
  order: object
  suggestions: any[]
}
export type FieldProperty = string | Record<string, any>

export type FieldErrors = Record<string, FieldProperty>
export type FieldValues = Record<string, any>
export type FieldSuggestions = Record<string, string[]>

export interface TopAlert {
  variant: string
  message: string
}

export const IGNORE_HISTORY_FIELDS = Object.freeze([
  'trades_settings',
  'custom_fields',
  'for_makemodels',
  'leasing_options',
  'external_certificate',
  'finance_options',
  'is_financed',
  'photos',
  'pickup_location',
  'geolocation',
  'location',
  'address',
  'postcode'
])

export enum FuelTypeId {
  ELECTRIC = 3,
  LPG_GASOLINE = 4,
  GASOLINE = 1,
  DIESEL = 2,
  HYBRID_GASOLINE = 8,
  HYBRID_DIESEL = 10,
  CNG_GASOLINE = 11,
  OTHER = 6
}

export interface TouchInfo {
  available: boolean
  cost: number
}

export interface FormClassifiedInfo {
  categories: Category[]
  baseCategory: Category
  category: number
  created: string
  deleted: boolean
  deletedDate: string
  expires: string
  hidden: boolean
  maxAllowedCategories: number
  modified: string
  nonPublic: boolean
  seoUrl: string
  states: ClassifiedStates
  title: string
  touch: TouchInfo
  unreadMessagesCount: number
  promotion: Promotion
  payment?: PaymentsInfoResult
  saleRequests: { eligible: boolean }
}

// TODO: better types for this in edit
export interface FormClassified {
  info?: FormClassifiedInfo
  fieldValues: FieldValues
  incompleteFieldValues?: FieldValues
  id?: number
  owner?: any
}

export interface SchemaExtras {
  leasingAllowedForUser: boolean
  onlyDealersAllowed: boolean
  showAnimalsDisclaimer: boolean
}

export interface SchemaResult {
  category: FormCategory
  schema: {
    fieldsets: Fieldset[]
    genericFormFields: any
  }
  extras?: SchemaExtras
}

export interface FieldValuesResult {
  registerReferenceCode?: string
  classified: FormClassified
  allCategories: FormCategory[]
}

export interface SubmitResult {
  classified: Classified
  dispatcher: any
  message: string
}

export interface ChangeCategoryResult {
  categories: FormCategory[]
  message: string
}

export interface ErrorResult {
  fieldErrors?: FieldErrors
  message?: string
  status: HttpStatus
  reason?: {
    id: number
  }
}

export interface FieldChange {
  name: string
  value: any
  options?: FieldOptions
}

export interface ClientValidator {
  key: string
  validator: Function
}
export interface HistoryEntry {
  undoTo: FieldChange
  redoTo: FieldChange
}

export interface FormConfig {
  settings?: Settings
}

export interface Settings {
  fieldColumns: object
}
